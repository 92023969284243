#catapultannounce-content{

    .catapultannounce-slideshow-view{//Slideshow View
        .catapultannounce-slideshow figcaption {
            top: 65%;
        }

        .slick-prev:before, .slick-next:before {
            padding: 10px;
            background: rgba(0, 0, 0, 0.6);
        }

        .slick-prev:before, .slick-next:before {
            color: #ffffff;
            z-index: 11;
            width: 35px;
            height: 40px;
        }

        .slick-next {
            right: 21px;
        }
        .slick-next:before {
            font-family: FontAwesome;
            content: '\f054';
            font-size: 30px;
            line-height: 1;
            color: #fff;
            opacity: 0.5;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .slick-prev {
            left: 0px;
        }
        .slick-prev:before {
            font-family: FontAwesome;
            content: '\f053';
            font-size: 30px;
            line-height: 1;
            color: #fff;
            opacity: 0.5;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    
.catapultannounce-feed-view{/*homepage feed view*/
    
        h2.catapultannounce-title {
        float: left;
        font-size: 150% !important;
        color: @color-primary !important;
        font-family: @font-primary;
        }

        h3.catapultannounce-title {

          font-size: 20px;
          line-height: 32px;
          margin: 0;
          font-family: @font-primary;
          font-weight: bold;
          text-align: left;
          display: block;
          padding: 0px 0px;
          color: @color-primary !important; 

        }

        article.catapultannounce-description.is-truncated {

          font-size: 14px;
          line-height: 23px;
          font-family: @font-primary;
          font-weight: normal;
          margin: 0;
          color: #222;
          padding: 0px 0px;

        }

        .catapultannounce-item span.readmore
        {
            color: #6a6a6a;
            border: none;
            margin-left: 0px;
            padding: 10px 0px;
            border-radius: 4px;
            font-size: 14px;
            line-height: 14px;
            font-weight: bold;
            white-space: nowrap;
            text-decoration: underline;
            font-style: italic;
            font-weight: normal;
        }

        .catapultannounce-item:hover span.readmore {
            background-color: transparent;
            border-color: transparent;
            color: #000;
        }  

        span.readmore:after {
            content: "\f101";
            font-family: "fontawesome";
            padding-left: 3px;
        }

        .annoucement ul li {
            display: block;
            padding: 25px 25px 25px;
        }

        li.catapultannounce-item:hover .catapultannounce-image-holder {
            transform: scale;
        }

        .catapultannounce-image-holder .catapultannounce-image{
            padding-bottom:65%;
            margin-left:0px;
            border:none;
            transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
        }

        .catapultannounce-text .catapultannounce-title-row h3.catapultannounce-title:hover {
            color: #cc000	
        }

        li.catapultannounce-item:hover .catapultannounce-image-holder .catapultannounce-image {
            transform: scale(1.1);
        }

        .catapultannounce-image-holder {
            width: 20%;
            display: table-cell;
            vertical-align: top;
            padding-right: 20px;
        }

        .catapultannounce-feed-view .catapultannounce-list li.catapultannounce-item:first-of-type {
            border-top: 1px solid @color-line;
        }

        li.catapultannounce-item:nth-child(2n) {
            background-color: transparent;
        }

        li.catapultannounce-item:nth-child(2n):hover {
            background-color: transparent;
        }

        li.catapultannounce-item.catapultannounce-item-priority
        {
            background: transparent;
        }

        li.catapultannounce-item.catapultannounce-item-priority:nth-child(2n)
        {
            background: transparent;
        }

        li.catapultannounce-item {
            border-bottom: 1px solid @color-line;
        }

        li.all-catapultannounce-item {
            text-align: left;
        }

        date.catapultannounce-date {
            color: #6a6a6a;
            margin-right: 25px;
        }
    
        li.all-catapultannounce-item:last-of-type:hover {
            background-color: transparent;
        }

        ul {
            display: block;
            padding: 14px 0 14px 0;
        }

        ul li {
            display: block;
            padding: 15px 0 15px;
        }

        ul li:hover img {
            -moz-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        ul li:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        a.catapultannounce-link .catapultannounce-title h3 {
            color: @color-primary !important;

            &:hover {
             color: lighten(@color-primary, 30%) !important;	
            }
        }
    
    }/*//homepage feed*/

        a.all-catapultannounce-link {
            color: @color-primary;
            border: 1px solid @color-primary;
            font-weight: 400;
            text-decoration: none;
            font-size: 16px;
            border-radius: 0px !important;
            margin:0px 0px -40px 0px;
            padding: 10px 20px;
            text-transform: uppercase;

            &:hover {
                background: @color-primary;
                color: #fff;
                text-decoration: none;
            }
        }


    
}//catapultannounce-content

    .catapultannounce-popup footer {
       background: url(../images/bg_pop_wave.jpg) !important;
    }

    .catapultannounce-popup .catapultannounce-title-row h2.catapultannounce-title {
        float: left;
        font-size: 150% !important;
        color: @color-primary !important;
        font-family: @font-primary;
        margin-top:13px !important;
        margin-bottom: 10px !important;
        }

    .catapultannounce-popup .catapultannounce-title-row {
        height: auto !important;
        position: relative !important;
        padding-bottom: 0 !important;
        }   

    date.catapultannounce-date {
            float: right;
            font-size: 11px;
            padding: 0;
            background: transparent;
            color: #6a6a6a;
        } 

    .catapultannounce-popup article {
        top: 6em!important;
        bottom: 105px!important;
    }

    .catapultannounce-popup footer a {
        text-decoration: none;
        color: #fff !important;
        background: rgba(255,255,255, .20) !important;
        padding: 6px 30px !important;
        margin: 8px 8px 8px 0;
        border: 1px solid #fff !important;
        transition: background 0.2s ease;

        &:hover {
        background: rgba(255,255,255, .4) !important;	
        }
    }

    @media (max-width: 1250px){
        .catapultannounce-feed-view{/*homepage feed view*/
            #catapultannounce-content div.catapultannounce-title-row{
                height:auto;
                padding-bottom: 0px;
                height: auto !important;
            }

            #catapultannounce-content h3.catapultannounce-title {
                font-size: 19px;
                line-height: 32px;
                margin: 0;
                font-family: @font-primary;
                font-weight: bold;
                text-align: left;
                display: block;
                padding: 0;
                color: @color-primary !important;
            }

        .catapultannounce-popup .catapultannounce-title-row h2.catapultannounce-title {
            float: left;
            font-size: 150%;
            color: @color-primary;
            font-family: 'Lato', sans-serif;
            margin-top:13px;
            }
        }


    }

    @media (max-width: 768px){
        #catapultannounce-content{
            .catapultannounce-slideshow-view .catapultannounce-slideshow figcaption{
                position:relative;
            }
            div.catapultannounce-title-row{
                height:auto;
                padding-bottom:1em;
            }

            .catapultannounce-title {
                height: 1.25em;
                padding: 0px 0px 0em 0px;
            }

            date.catapultannounce-date {
                float: left;
            }

            a.all-catapultannounce-link {
                padding: 10px 20px;
            }
        }
        .catapultannounce-popup .catapultannounce-title-row h2 {
        float: left;
        font-size: 150% !important;
        color: @color-primary !important;
        }
        
/*-------------------------
Announcements
------------------------*/
    
#catapultannounce-content{
    
.catapultannounce-feed-view{
    
        ul li {
            display: block;
            padding: 15px 15px 15px 18px !important;
        }

        .catapultannounce-image-holder .catapultannounce-image {
            padding-bottom: 100% !important;
            margin-left: -18px !important;
            border: none;
            transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
        }

        .catapultannounce-image-holder {
            width: 33% !important;
            display: table-cell;
            vertical-align: top;
            padding-right: 20px;
            padding-left: 20px;
        }

        h3.catapultannounce-title {
            font-size: 18px;
            line-height: 24px;
            margin: -7px 0px -20px 0px !important;
            font-family: 'Lato', sans-serif;
            font-weight: bold;
            text-align: left;
            display: block;
            padding: 0;
            color: @color-primary !important;
        }

        date.catapultannounce-date {
            float: left;
            font-size: 11px;
            padding: 0;
            background: transparent;
            color: #999;
            position: relative !important;
        }

        a.all-catapultannounce-link {
            padding: 12px 20px !important;
            margin-bottom: 20px;
        }

        article.catapultannounce-description.is-truncated {
            margin-right: 15px !important;
        }
    
    }//catapultannounce-feed-view
    
}//catapultannounce-content
        
    }

