// ==========================================================================
// Tabbed Content Card Styles
// ==========================================================================
.responsive-tabs__heading{ 
    background-color: #efefef !important;
    font-size: 120%;
    margin-bottom: 10px !important;
    padding: 5px 0 !important;
    text-align: center;
    line-height: normal !important;
    
    @media (max-width: @mobile-breakpoint) {
        border-radius: 0;
        box-shadow: none;
        font-family: @font-primary;
        font-weight: 400;
        font-size: 100% !important;
    }
}

.ccms-tabbedcontent-tab {
        &:extend(.responsive-tabs__heading);
        line-height: 2 !important;
        margin: 0 0.5px !important;
        padding: 0 15px !important;
        font-family: @font-tertiary !important;
        font-size: @font-size !important;
        border-width: 1px 1px 0px !important;
        border-top-left-radius: 2px !important;
        border-top-right-radius: 2px !important;
        letter-spacing: 0.03125rem;
    }

    .ccms-tabbedcontent-tab.ccms-tabbedcontent-active, .responsive-tabs__heading--active {
    &:hover, & {
        background: @color-primary !important;
        .text-white  !important;
    }
        
    &:after {
        border-bottom: 10px solid #fff;
        border-top: 0;
    }
        
}

.responsive-tabs__list__item:hover, .responsive-tabs__heading:hover {
    background: @color-secondary !important;
    .text-white  !important;
}

.responsive-tabs__list {
    border-bottom: 5px solid @color-primary !important;
    margin: 0 !important;
}
.responsive-tabs__panel {
    padding: 20px !important;
}

/* -------------------
   Facebook Feed
--------------------*/

.fb-feed-header {
    width:100%;
    height: 78px;
    background-color: lighten(@color-secondary, 5%);
}

.fb-feed-header-cont {
    background-image: url(../images/icon_fb_feed.png);
    background-repeat: no-repeat;  
    padding-left: 50px;
    font-family: @font-secondary, serif;
    font-size: 34px;
    text-transform: uppercase;
    color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
    height: 78px;
    left:24px;
    position: relative;
    padding-top:16px;
}

.fb-feed {
    width:100%;
    height: 362px;
    margin: auto;
    border-left: 1px #ccc solid;
    border-right: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
    text-align: center;
}

.fb-feed-wrap {
    width:100%;
    height: 360px;
    margin: 0 auto;
}

/* -------------------
   Twitter Feed
--------------------*/

.tw-feed-header {
    width:100%;
    height: 78px;
    background-color: lighten(@color-secondary, 5%);
}

.tw-feed-header-cont {
    background-image: url(../images/icon_twitter_feed.png);
    background-repeat: no-repeat;  
    padding-left: 94px;
    font-family: @font-secondary, serif;
    font-size: 34px;
    text-transform: uppercase;
    color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
    height: 78px;
    left:0px;
    position: relative;
    padding-top:16px;
}

.tw-feed {
    width:100%;
    height: 362px;
    margin: 0;
    border-left: 1px #ccc solid;
    border-right: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
}

.tw-feed-wrap {
    width:100%;
    height: 360px;
    margin: 0 auto;
}