// ==========================================================================
// Mixins
// ==========================================================================

/*Functionality to hide title & tagline when their included in the logo*/
.checkLogo(){
    display: inherit;
}

.checkLogo() when (@logo-has-title){
    display: none;
}

// Border Radius
.border-radius (@radius: 5px) {
    -webkit-border-radius:      @radius !important;
    -moz-border-radius:         @radius !important;
    border-radius:              @radius !important;
}
.border-top-radius(@radius: 5px) {
    border-top-right-radius:    @radius;
    border-top-left-radius:    @radius;
}
.border-right-radius(@radius: 5px) {
    border-bottom-right-radius: @radius;
    border-top-right-radius: @radius;
}
.border-bottom-radius(@radius: 5px) {
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
}
.border-left-radius(@radius: 5px) {
    border-bottom-left-radius:  @radius;
    border-top-left-radius:  @radius;
}
// Opacity
.opacity (@opacity: 0.5) {
    -webkit-opacity:      @opacity;
    -moz-opacity:         @opacity;
    opacity:              @opacity;
}
.blackface-visibility(...){
    -webkit-backface-visibility: @arguments;
    -moz-backface-visibility: @arguments;
    -ms-backface-visibility: @arguments;
    backface-visibility: @arguments;
}
.transition(@transition: all 0.2s ease) {
  -webkit-transition:   @transition;
     -moz-transition:   @transition;
      -ms-transition:   @transition;
       -o-transition:   @transition;
          transition:   @transition;
}
.trans-prefix(@trans:0.4s, @args: opacity 0.4s){
     -webkit-transition: -webkit-transform @trans, @args;
     -moz-transition: -moz-transform @trans, @args;
     -ms-transition: -ms-transform @trans, @args;
     -o-transition: -o-transform @trans, @args;
	 transition: transform @trans, @args;   
}
.trans-prefix2(...){
     -webkit-transition: -webkit-transform @arguments;
     -moz-transition: -moz-transform @arguments;
     -ms-transition: -ms-transform @arguments;
     -o-transition: -o-transform @arguments;
	 transition: transform @arguments;   
}
.transform(@arguments) {
  -webkit-transform: @arguments;
  -moz-transform: @arguments;
  -o-transform: @arguments;
  -ms-transform: @arguments;
  transform: @arguments;
}
.transform-origin (...) {
  -webkit-transform-origin: @arguments;
     -moz-transform-origin: @arguments;
      -ms-transform-origin: @arguments; /* IE 10+ */
       -o-transform-origin: @arguments;
          transform-origin: @arguments;
}

.transform-style (@value: flat) { /* ( flat | preserve-3d ) */
  -webkit-transform-style: @value;   
     -moz-transform-style: @value; /* FF 10+ */
      -ms-transform-style: @value; /* IE 10+ */
          transform-style: @value; 
}
.columns(@width: 50%, @count: 2, @gap: 20px) {
  -webkit-column-width: @width;
     -moz-column-width: @width;
          column-width: @width;
  -webkit-column-count: @count;
     -moz-column-count: @count;
          column-count: @count;
  -webkit-column-gap:   @gap;
     -moz-column-gap:   @gap;
          column-gap:   @gap;
}


// ==========================
// Mixins - Shadow Family
// ========================== */

// Usage: #shadow > .box-shadow (0px 1px 2px rgba(0,0,0,0.5))

#shadow {

  .box-shadow(@horizontal: 0, @vertical: 1px, @blur: 2px, @color) {
    -webkit-box-shadow: @horizontal @vertical @blur @color;
    -moz-box-shadow: @horizontal @vertical @blur @color;
    box-shadow: @horizontal @vertical @blur @color;
  }

  .inner-shadow(@horizontal:0, @vertical:1px, @blur:2px, @alpha: 0.4) {
    -webkit-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    -moz-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
  }

  .text-shadow(@horizontal: 0, @vertical: 1px, @blur: 2px, @alpha: 0.4) {
    text-shadow: @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
  }

}


// ==========================
// Mixins - Gradient Family
// ========================== */

// Usage: #gradient > .vertical (#555, #333)

#gradient {

  .horizontal(@startColor: #555, @endColor: #333) {
    background-color: @endColor;
    background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(to right, @startColor, @endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@startColor),argb(@endColor))); // IE9 and down
  }

  .vertical(@startColor: #555, @endColor: #333) {
    background-color: @endColor;
    background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down
  }

  .directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
    background-color: @endColor;
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(@deg, @startColor, @endColor); // Standard, IE10
  }

  .horizontal-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
    background-color: mix(@midColor, @endColor, 80%);
    background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
    background-image: -webkit-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
    background-image: -moz-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
    background-image: -o-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
    background-image: linear-gradient(to right, @startColor, @midColor @colorStop, @endColor);
    background-repeat: no-repeat;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down, gets no color-stop at all for proper fallback
  }

  .vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
    background-color: mix(@midColor, @endColor, 80%);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
    background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
    background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-repeat: no-repeat;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor),argb(@endColor))); // IE9 and down, gets no color-stop at all for proper fallback
  }

  .radial(@innerColor: #555, @outerColor: #333) {
    background-color: @outerColor;
    background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@innerColor), to(@outerColor));
    background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -o-radial-gradient(circle, @innerColor, @outerColor);
    background-repeat: no-repeat;
  }

}


// ==========================
// Mixins - Background Texture
// ========================== */

// BG Texture 1
.bg-texture-1(@color: @color-body, @opacity: 92%) {
  background: linear-gradient(fade(@color, @opacity), fade(@color, @opacity)), url("../images/white_wave.jpg");
}

// BG Texture 2
.bg-texture-2(@color: @color-body, @opacity: 92%) {
  background: linear-gradient(fade(@color, @opacity), fade(@color, @opacity)), url("../images/floral.jpg");
}

// Hover Scale
.hover-scale(@scale: 1.1) {
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    .transition(transform .1s ease);
    &:hover {
        .transform(scale(@scale));
    }
}

// ==========================
// Mixins - Structural
// ========================== */

// Text Truncate
// (inline-block or block required)
// 
// .banner p {
//   display: inline-block;
//   max-height: 200px;
//   .text-truncate();
// }
//

.text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// ==========================
// Mixins - Core
// ========================== */

// Font Sizes (REMs with PX fallback for older browsers)
.font-size (@size) {
    @remValue: @size / @font-size;
    @pxValue: (@size);
    font-size: ~"@{pxValue}px"; 
    font-size: ~"@{remValue}rem";
}

// Line Height Sizes (REMs with PX fallback for older browsers)
.line-height (@size) {
    @remValue: @size / @font-size;
    @pxValue: (@size);
    line-height: ~"@{pxValue}px"; 
    line-height: ~"@{remValue}rem";
}