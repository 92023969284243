// ==========================
// Style Variables
// ==========================

// Fonts
@font-primary:			    'Lato', Arial, sans-serif;
@font-secondary:	        'Cormorant Garamond', Arial, sans-serif;
@font-tertiary:		        'Lato', Arial, serif;		
@font-icon:					'FontAwesome';

// Text Sizes
@font-size:   				16px;
@line-height: 				1.6;

@logo-has-title: false;

// Colors - Backgrounds

/* As Designed (Default) */
@color-primary:			    #16246d; // Dark Blue
@color-secondary:			#2a6292; // De-Saturated Blue
@color-tertiary:			#8c8c8c; // Light Gray

/* Scheme Test 1 */
//@color-primary:			#5A7A4B; // olive
//@color-secondary:			#804B58; // violet
//@color-tertiary:			#4A3F25; // dark brown


/* Scheme Test 2 (Monochromatic) */
//@color-primary:			#B53126; // red (light)
//@color-secondary:			#94170E; // red (medium)
//@color-tertiary:			#690800; // red (dark)

/* Scheme Test 3 (Dark) */
//@color-primary:			#151333; // purple (darker)
//@color-secondary:			#423F5A; // purple (dark)
//@color-tertiary:			#19181B; //purple (darkest)

/* Scheme Test 4 (Light) */
//@color-primary:			    #E7E792; //yellow-green (lightest)
//@color-secondary:			#CECE68; //yellow-green (lighter)
//@color-tertiary:			#B2B23F; //yellow-green (light)


@color-navbar:				#0d37a5; // Dark Blue2
@color-navbar-text:		    #ffffff;
@color-body:				#ffffff;

// Colors - Type
@color-text:  			    #444;
@color-link:  			    #004a80;
@color-line:			    #eeeeee;
@color-link-hover: 		    darken(@color-link, 10%);

// Colors - Social Media
@color-facebook: #3b5998;
@color-twitter: #55acee;
@color-youtube: #bb0000;
@color-instagram: #8a3ab9;
@gradient-instagram: radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF);

//Layout
@max-content-width:         1280px;

//Patterns
@pattern-square: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkCAMAAADqvX3PAAAAKlBMVEUAAADX19fX19fBwcHT09PX19fW1tbT09PW1tbV1dXOzs7Ozs7BwcHV1dX5uIg2AAAADnRSTlMAPQAKH0czAAApFAAAAHys1goAAAHwSURBVHja7ZfdcuMwCEb1GUIDcd//dZuk2n5rZzCKOzuzFzo3+XNOBAIHtQosxwi0XUw/jjh2qN1pVzV43FJwJIDLuq5tvaNmzxeEZI5wWP/p9v0gypgGYhGH8fL28yyM7ycOJkFlJW3tZDHhRdBjIO22JR6a1BGMgTAWopvr6BC1jSB1MDWyXYfvkpDlg4iiV83lITiuoXZL+U4/ltcNq2MhogaYBWMYjYU8HHfSRdTrCIOLYImemrfz8dMO6DlVGYyFMWz3tmuGY6GgO1ikXVM51JDXurBO0nyEcyPTnvOXvmyb0hzqfV64jUUYw47Re9CZe6EbeC+M39+TcfK/gTFZuxZgOSag7aMASwGkrQW1Y2lLwXRMx3RMx3T8Pw67FpSOcA5RCccOcTMNL05aRw7Fn2kizA7+1VNH6ENAOBgOxiIK888dHPVqh+Q/yeknzQfHkhx+nqyjT0QFzDUd2Skp53lp7PdFnEkYIhzQv/KRzJUV0QdDSON5+S14CEW0vqSTiAPWWDknCLsY0BZxnNP0L/Z9YQEO8yznXZ2yNwfgTu7rNFgj9aamvS9eZljMTNOeoybSGNwQkvZ+3XrCU07t4HmWSBjDLBzEwdSzhMYcRPRZNYmgdjA1FyahcKQIqpKZs8N0TMd0TMd0/BvHF8n9f8tHo7HcAAAAAElFTkSuQmCC';

@pattern-square-dark: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABkBAMAAAAvTZDOAAAAD1BMVEUAAAAoKCgtLS0pKSkqKio+AxLlAAAABXRSTlMAEgcPDLMa38QAAAFbSURBVEjH7ZZRkoMgDIaB6QEEPUCsHECDB7B6/zutO7hGCJHXnal5aKczX/PHJPyi8miy3xpUC7fIEEZlplVGzLREcJYQf0qYForIB6/5kCMmQFrVnCPDmZr+s1wRs4vw6JGQXERR6gPZUAlh7Loj1KlyIlQN1SkkmlpU9/GerLsnhk41fScJxWbEckEkwt9DD04QubTOU6J0Mwgp9cYHyMbo52w+rrAMFriIvFLyYm5xFvX1lg4JiY3Vo9ZDFdHqQR7kQZIYq4h3NWRD38IdYuyyf4RVRt7d4SZOQvo58U6GZG77QY4MHfNxQsiqeOkRIW3u1XAim+Dn3kJEyFILlo6/SNJNLjZrUJU31MuOKlYqS01N7JKYowV9fEuVuPhEyWy5u2vChZc/zejFqg7IJr1hUuc5d53MFtLrDCF8Y0xAcXc/HTWBIXTxylupCzeP5f8c+wd5kK9CfgDRuCb3lAfc7AAAAABJRU5ErkJggg==';