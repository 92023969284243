// ==========================================================================
// Slide Show Card Styles
// ==========================================================================
//.ccmsImageRotator {
//    margin-bottom: 0;
//    .slick-slider {
//        margin-bottom: 0;
//        .slick-slide {
//            border-radius: 5px !important;
//            &::after {
//                background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #131313 100%, #151515 50%) repeat scroll 0 0;
//                border-radius: 0 0 5px 5px;
//                bottom: 0;
//                content: "";
//                height: 75px;
//                left: 0;
//                position: absolute;
//                right: 0;
//                width: 100%;
//                z-index: 0;
//            }
//            .ccms-ImageRotator-overlay {
//                background: rgba(0, 0, 0, 0.4);
//                border-radius: 0 0 5px 5px;
//                height: 15%;
//                p {
//                    position: relative;
//                    text-align: center;
//                    z-index: 1;
//                }
//            }
//        }
//        .slick-prev, .slick-next {
//            background: rgba(0,0,0,0) !important;
//            margin-top: 24%;
//            z-index: 2;
//            &:hover {
//                background: rgba(0,0,0,0) !important;
//            }
//        }
//        .slick-prev::before {
//            content: "\f177";
//        }
//        .slick-next::before {
//            content: "\f178";
//        }
//        .slick-dots {
//            display: none !important;
//        }
//    }
//}

.ccmsImageRotator .ccms-ImageRotator-overlay p{font-size:150%;}