// ==========================================================================
// Banner Card Styles
// ==========================================================================

/*Banner Loading Screen*/
.ccms-banner-slider {
    background: #000000;
    background: url(https://media1.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif) center no-repeat;
    height:40vw;
    .ccms-banner { 
        opacity: 0; 
    }
    
}
/*Banner Loaded*/
.ccms-banner-slider.slick-slider.slick-initialized {
    background: none;
    height: 100%;
    .ccms-banner { 
        opacity: 1;
        position: relative;
        transition: opacity 0.3s ease-in;
    }
}

/* ============================= Slider ============================= */ 

.slick-slider {margin-bottom: 0;}

.slick-list,
.slick-track {height: 100%;}

.ccms-contentelement-Banner {
    height: 100%;
}

.ccms-banner { 
    outline: none; border: none; background-position: center; background-size: cover; background-repeat: no-repeat;
    
    &:after { background-color: transparent; }
}

.ccms-banner-overlay {
    position: absolute;
    color: contrast(@color-primary, #111, #FFF);
    max-width: 30%;
    left: 15%;
    top: 35%;
    padding: 0;
    text-align: left;
    z-index: 11;
    text-transform: uppercase;
    text-shadow:0px 0px 15px rgba(0,0,0,.65);
    
    &:before {
        content: " ";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        /*border-bottom: 15px solid fade(@color-primary, 70%);*/
        bottom: 100%;
        left: 0;
        right: 0;
        margin: auto;
    }

    p {
        color: inherit; 
        font-weight: 300; 
        font-size: 1.875rem;
        font-size: 1.5vw;
        border-top: none;
        border-bottom: none;
        overflow: initial;
        white-space: normal;
        font-family: @font-primary;
        padding: 0;
    }

    h1 {
        font-family: @font-primary;
        font-size: 3.25rem;
        font-size: 2.5vw;
        font-weight: 100;
        letter-spacing: 0.03125rem;
        padding: 1%;
        white-space: normal;
        line-height: 1.3;
    }

    a.ccms-banner-button, a.ccms-banner-button:link, a.ccms-banner-button:visited {
        color: inherit; 
        font-size: 0.75rem;
        float: left;
        letter-spacing: 0.03125rem;
        border: 1px #fff solid;
        text-transform: uppercase;
        box-shadow: none;
        background-color: rgba(255, 255, 255, .35);
        display: inline;
        padding: 12px 60px;
        border-radius: 0px;
        margin-left: 10px;
        
        strong {font-weight: 500;}

        &:hover {color: @color-primary; border-color: #fff; background-color: rgba(255, 255, 255, .85); text-shadow: none;}
    }
}

.ccms-banner-slider .slick-slide:before {
    content:"";
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    background:rgba(0,0,0,.25);
}

.ccms-banner-slider {
    .slick-slide{
        height:0px;
        padding-bottom:@banner-height;
        padding-bottom:34.5vw;
    }
    .slick-prev, .slick-next { 
        color:#ffffff;
        z-index:11;
        width: 35px;
        height: 40px;
        
        
        &:before {
            font-family: @font-icon;
            padding:10px;
            background: rgba(0,0,0,.6);
        }
        &:hover,{
            background: rgba(0,0,0,0.8);
        }
    }
    
    .slick-prev {
        left: 0;
        &:before {content: '\f053';}
    }
    
    .slick-next {
        right: 0;
         &:before {content: '\f054';}
    }

    .slick-dots {    
        z-index: 11;
        margin: 0 0 95px 0;

        li button {
        color:#ffffff;
            &:before {
                content: '\f111';
                font-size: 14px;
                font-family: @font-icon;
                color: #FFF;
                opacity: 0.75;
            }
        }

        li.slick-active button:before {
            color: #FFF;
            opacity: 1;
        }

        li button:hover:before, li button:focus:before {
            color: #FFF;
        } 
    }
}





.rspPane {
    .homepages {
        .ccms-banner-slider {height: @banner-height;}
        .banner {padding-bottom: 0 !important;}
    }
   
    .banner {
        &, .ccmsBanner {height: 100% !important; overflow-x: hidden;}
        .ccmsBanner { position:relative !important;
            .ccms-contentelement { margin-bottom: 0;}
        }
        .slick-slider {margin-bottom: 0 !important;}
        .ccms-banner {height: 100% !important;}
        .slick-dots {display: none !important;}
    }
    
    /*Styling to move the banner controls to the bottom for homepage editing*/
    .slider .RadDock_Catapult .rdTable tr.rdTop td.rdCenter {
        position: absolute;
        bottom: 70px;
        z-index: 1;
    }

    .banner .RadDock_Catapult tr.rdTop, .banner .RadDock_Catapult tr.rdTop td.rdLeft, .RadDock_Catapult tr.rdTop td.rdRight {height: 0px;border: 0px;}
    
    .slider .RadDock_Catapult .rdTable {
        min-height: 480px;
    }
    
}

@media only screen and (min-width: 1640px) {
    .ccms-banner-overlay {
    margin-left: -565px !important;
    left: 50%;
    }
}
	

/*Preview Pane Adjustments*/

#previewPanel {
    .ccms-banner-slider {
        .slick-dots, .slick-prev, .slick-next { display: none !important;}
    
        .slick-slide {height: 100% !important; padding: 30px !important;}
        
        .ccms-banner-overlay {position: relative !important; bottom: auto !important; right: auto !important; }
    }
}

/*Tablet Adjustments*/
@media only screen and (min-width: @tablet-breakpoint) and (max-width: (@desktop-breakpoint - 1px)) {

    .ccms-banner-slider {/*Tablet Banner Height*/
        .slick-slide{
            padding-bottom:40vw;
        }
    }
    
}

/*Mobile Adjustments*/
@media only screen and (max-width: @mobile-breakpoint) {
    
    .ccms-banner-overlay {
        margin: 0 0 20px;
        padding:45px;
        top: 0%;
        right: auto;
        left: 0%;
        bottom: auto;
        .transform(none);
        max-width: 100%;
        width: 100%;
        height: auto;
        overflow: hidden;
        text-align: center;
        bottom: 0;
        filter: progid:DXImageTransform.Microsoft.Shadow(color=#000000,direction=45);
        text-shadow: 3px 3px 9px rgba(0, 0, 0, 0.25), -1px -1px 9px rgba(0, 0, 0, 0.25), 1px -1px 9px rgba(0, 0, 0, 0.25), -1px 1px 0 rgba(0, 0, 0, 0.25), 1px 2px rgba(0, 0, 0, 0.25);
        h1 {
            font-size: 1.8rem;
        }
        p { display: none;}
        .ccms-banner-button {text-align: center; float: none;}

        .ccms-banner-slider .slick-dots {
            margin: 0px 0 35px 0;
        }

        .banner {    
            aside{display: none;}/*No banner side buttons in mobile*/
        }

        .ccms-banner-slider .slick-prev, .ccms-banner-slider .slick-next {
            top: 55%;
        }
        
    }

    .ccms-banner-slider{/*Match Announcement Slideshow Styles*/
        
        .slick-slide {
            margin-bottom: 65vw;
            &:before {
                background: rgba(0, 0, 0, 0);
            }
        }
        .slick-prev, .slick-next {
            top: 30%!important;
        }
        .slick-dots {
            margin: 0px 0 25px 0!important;
            li button:before {
                color: @color-secondary;
                text-shadow: 0px 0px 10px rgba(0,0,0,.35);
                opacity: 0.5;
            }
        }
    }
        
    .ccms-banner-overlay {
        background: rgba(0,0,0,.75);
        height: 50vw;
        padding: 0px;
        top: 100%;
        overflow: hidden;
        display: block!important;
        
        h1 {
            font-size: 1.5rem;
            margin-top: 20px;
        }
        p {
            display: inherit;
            font-size: 3.5vw;
        }
        a.ccms-banner-button, a.ccms-banner-button:link, a.ccms-banner-button:visited {float:none;}
    }
}

