// ==========================================================================
// Google Calendar Card Styles
// ==========================================================================
#calendarPanel, .ccmsGoogleCalendar{
    font-family: @font-primary !important;
    /*border-left: 1px #ccc solid;
    padding-left: 20px;*/
    
    a { color: #444;}
    h2 {font-family: @font-primary;}
    
    .fc-unthemed td { border-color: #CCC;}
 
    .fc-day-header {
        background: transparent; 
        color: @color-primary; 
        border-color: transparent; 
        border-width: 0;
        font-weight: 400;
        font-size: 0.875rem;
        text-align: right;
        text-transform: uppercase;
    }
    .fc-head td {border-width: 0;}
    
    .fc-day { background: #FFF; border-color: #BBB;}
    .fc-other-month {background: #f9f9f9; color: #DDD;}
    
    .fc-head td {border-width: 0;}
    
    .fc-day-number {
        background: transparent !important;
        color: #333;
        font-weight: bold;
        font-size: 75%;
        padding-right: 5px !important;
    }
    
    .fc-today {background: transparent !important;}
    
    .fc-day-number.fc-today {
        background: @color-secondary !important;
        color: contrast(@color-secondary, #ffffff, #111111) !important;
    }
    
    .fc-event {
        border-radius: 0px;
        
        &:hover {
            background-color: @color-secondary !important;
            color: contrast(@color-secondary, #ffffff, #111111) !important;
        }
        
        .fc-content {text-overflow: ellipsis;}
    }
    
    
    .fc-corner-right, .fc-corner-left {border-radius: 0 !important;}
    
    .fc-state-default {
        border-color: rgba(0, 0, 0, 0.1) !important;
        background-color: #f5f5f5;
        background-image: none;
        color: @color-text;
        outline: none !important;
        text-shadow: none !important;
        
        font-size: 0.875rem;
        font-weight: 400;
    }
    
    .fc-state-active {
        background-color: @color-primary !important;
        color: contrast(@color-primary, #ffffff, #111111);
        box-shadow: none !important;
    }
    
    .fc-state-hover {
        background-color: #e9e9e9 !important;
    }
    
    .fc-state-down {
        box-shadow: none !important;
    }
    
    
    
    .ccms-gcalendar-legend li {
        font-size: 0.875rem;
    }
    
    
    
    /* Event Feed */
    ul.ccms-gcalendar-calendar-feed-list { 
        li { border: none; margin: 0; padding: 5px 10px; position: relative; border-radius: 0px;
            article { font-size: 1rem; width: auto; margin-left: 70px; float: none;
                div {font-size: 0.8125rem; padding-bottom: 0; margin-top: 4px; height:3.5rem; overflow: hidden;}
                h4 { color: @color-primary; font-family: @font-primary; font-size: 1.125rem; font-weight: bold; margin: 5px 0 0; padding: 0;}
                time {font-size: 0.875rem; font-family: @font-primary; font-weight: 300; margin-top: 4px; color: #000;}
                
//                @media only screen and (max-width: @mobile-breakpoint) { margin-left: 0; margin-right: 0;}
            }
            h3 { width: 65px; color: @color-primary; padding-top: 0.375rem; font-family: @font-primary; text-align: center; margin: 5px 0;  font-weight: 400;
                span { line-height: 1.2;}
                span.ccms-gcalendar-day {display: none;}
                span.ccms-gcalendar-date { font-size: 3rem; font-weight: 300;}
                span.ccms-gcalendar-month { font-size: 0.875rem;}
            }
            
            &.ccms-gcalendar-full {          
                color: inherit;
                border: 1px solid @color-primary;
                font-weight: 400;
                text-decoration: none;
                margin: 0 auto;
                width: 140px; 
                color: @color-primary;
                text-transform: uppercase;
                .transition();

                &:hover {
                    background-color: @color-primary;
                    color: #FFF;
                    text-decoration:none; 
                }
                

                &:after { display: none; }
            }
            
        }
    }    
}

/*Hayden's Calendar Adjustments*/

#calendarPanel ul.ccms-gcalendar-calendar-feed-list li h3 span.ccms-gcalendar-month, .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li h3 span.ccms-gcalendar-month{
    background-color: @color-secondary;
    padding: 3px;
}


#calendarPanel ul.ccms-gcalendar-calendar-feed-list li h3 span.ccms-gcalendar-date, .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li h3 span.ccms-gcalendar-date{
    background-color: @color-primary;
    font-size: 28px;
    padding: 4px;
}

#calendarPanel ul.ccms-gcalendar-calendar-feed-list li h3 span.ccms-gcalendar-month, .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li h3 span.ccms-gcalendar-month{
    background-color: @color-secondary;

}

#calendarPanel ul.ccms-gcalendar-calendar-feed-list li h3, .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li h3{
    color: contrast(@color-primary, #fefefe, #111111);
    border: 1px solid;
    padding-top: 1px;
}


#calendarPanel ul.ccms-gcalendar-calendar-feed-list li.ccms-gcalendar-full, .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li.ccms-gcalendar-full{
    width: 215px;
    line-height:34px;
    font-size: 16px;
    margin-top: 20px;
}

#calendarPanel ul.ccms-gcalendar-calendar-feed-list li article, .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li article{
    margin-left: 75px;
}

a.ccms-gcalendar-link:hover{
    text-decoration: none;
}


 @media (max-width: 768px){/*Moved From*/
    #calendarPanel ul.ccms-gcalendar-calendar-feed-list li, .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li {
        border: none;
        margin: 0;
        padding: 5px 18px !important;
        position: relative;
        border-radius: 0px;
    }

    .gcalendar-list-unstyled {
        list-style: none;
        padding: 0 0 20px 0 !important;
        margin: 0;
    }

    .ccmsGoogleCalendar ul.ccms-gcalendar-calendar-feed-list li.ccms-gcalendar-full {
        margin-left: 0 !important;
    }
}